import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import globalImg from '../../../assets/images/global.png';

export const AboutUs: React.FC = () => {
    return (
        <div className="bv-about-page page">
            <div className="breadcrumb-area">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center">
                        <div className="col-lg-5">
                            <div className="breadcrumb-title text-center">
                                <h2>About Us</h2>
                                <ul className="breadcrumb-list">
                                    <li>Home</li>
                                    <li> <FontAwesomeIcon icon={icon({ name: 'angle-double-right', style: 'solid' })} /> </li>
                                    <li>About</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-us-area section-padding-100-50 bg-overlay bg-img">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="about-us-content mb-100">
                                <h3>Why Trade Station?</h3>
                                <p>
                                    Trade Station was founded in 2018, with the primary mission to empower people to trade with confidence.
                                    With strong core values of integrity and innovation, we always put the customer first, and center all our
                                    efforts into enabling traders of all levels to unleash their true potential. Whether it is our customer
                                    service, diversity of instruments and variety of platforms or any of our other superior services – 
                                    we work hard to ensure our clients have a pleasant trading experience.

                                </p>
                                <p className="mb-50">
                                    Trade with the licensed and award-winning broker. Open an account today and enjoy 
                                    features and the full benefits that Trade Station has to offer.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="about-us-image welcome-thumb mb-100">
                                <img src={globalImg} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="single-counter-2 text-center mb-50 aos-init aos-animate" data-aos="flip-up" data-aos-delay="200">
                                <div className="counter-number-2">
                                    <p># 250+ instruments to trade including forex, cryptocurrencies, stocks, commodities and indices</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="single-counter-2 text-center mb-50 aos-init aos-animate" data-aos="flip-up" data-aos-delay="400">
                                <div className="counter-number-2">
                                    <p># A range of automated trading platforms and EA compatibility</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="single-counter-2 text-center mb-50 aos-init aos-animate" data-aos="flip-up" data-aos-delay="600">
                                <div className="counter-number-2">
                                    <p># Fixed &amp; competitive spread accounts</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="single-counter-2 text-center mb-50 aos-init aos-animate" data-aos="flip-up" data-aos-delay="800">
                                <div className="counter-number-2">
                                    <p># Client funds are held in segregated accounts for increased security</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-team-area section-padding-100-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2 className="heading-title">What Makes Us The Best</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-lg-4">
                            <div className="single-team-area team-2 text-center">
                                <div className="team-content-text text-center">
                                    <h4>Why More Traders Choose Us?</h4>
                                    <h6>For over 15 years, Trade Station has established itself as a major player in the online broker fi
                                        nancial field, with a proven track record of positive customer satisfaction. Our key responsibility is to 
                                        offer top notch services to all our traders.
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-4">
                            <div className="single-team-area team-2 text-center">      
                                <div className="team-content-text text-center">
                                    <h4>Trading Ambitions</h4>
                                    <h6>Trade Station has established a 3-stage process that our traders can join at any level, with respect to their experience and ambitions. The goal is to provide first class education, timely and actionable information on events that are affecting global markets and access to a wide range of trading products, making Trade Station one of the few one-stop trading sites in the industry.</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-4">
                            <div className="single-team-area team-2 text-center">
                                <div className="team-content-text text-center">
                                    <h4>Client satisfaction</h4>
                                    <h6>Client satisfaction has been our top priority since the very beginning.
                                    We aim not only to provide excellent customer support, but also on listening to clients’ feedback carefully.
                                    Many wonderful ideas were inspired by our customers.
                                    By the traders and for the traders!</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}